/**
 * Application entry point
 */
// require("babel-core/register");
// Load application styles
// import 'styles/index.scss';

// ================================
// START YOUR APP HERE
// ================================
const classAdder = require('chunks/class-adder');
/**
 * Header slider
 */
window.onload = () => {

    /**
     * Item Slider Init
     */
    (() => {
      if (document.querySelector('.item-slider')) {

        const slider = new flkty('.item-slider', {
          cellAlign: 'center',
          wrapAround: true,
          adaptiveHeight: true,
          percentPosition: true  

        });
        const goBackButton = document.body.appendChild(document.createElement('div'));
        goBackButton.classList.add('go-back-btn');
        goBackButton.addEventListener('click', () => history.back());
      }
    })();

    (() => {
      if (document.getElementById('offers-slider')) {
        const slider = new flkty('.offers-slider', {
          cellAlign: 'center',
          wrapAround: true,
          autoPlay: 5000,          
        });
      }
    })()

    /**
     * Load lazy-loaded images
     */
    const imgs = document.querySelectorAll('img[data-ll-src]');
    if (imgs.length) {
      imgs.forEach(img => img.src = img.getAttribute('data-ll-src'));
    }
}
